// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('van'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('stats'),
  document: getIcon('ic_document'),
  gas: getIcon('gas'),
  tuerca: getIcon('cog'),
  tuercas: getIcon('cogs'),
  llave: getIcon('wrench'),
  subr: getIcon('users-cog'),
  library: getIcon('library-outline'),
  car: getIcon('car-wrench'),
  organigrama : getIcon('organization'),
  administrativos: getIcon('administrativos'),
  presupuesto: getIcon('presupuesto'),
  contract : getIcon('contract'),
  handShake : getIcon('handshake'),
  relojControl : getIcon('reloj_control'),
  emergencia: getIcon('security'),
  printerAdmin: getIcon('printerscrew'),
  printer: getIcon('printer'),
  bodega: getIcon('bodega')
};
console.log("cargandonav")

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Inicio', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard, role: 'user'},
      { title: 'Solicitar Asistencia', path: PATH_DASHBOARD.ticket.new, icon: ICONS.ecommerce, role: 'user' }
      // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'Estadisticas', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking, role: 'user' },
      // {
      //   title: 'Marcas del reloj',
      //   path: PATH_DASHBOARD.marcaciones.propias,
      //   icon: ICONS.relojControl,
      //   role: 'gasUser'
      // },
      // {
      //   title: 'Flujo de Trabajo',
      //   path: PATH_DASHBOARD.kanban,
      //   icon: ICONS.kanban,
      //   role: 'user'
      // },{
      //   title: 'Metricas',
      //   path: PATH_DASHBOARD.kanbanStats,
      //   icon: ICONS.analytics,
      //   role: 'user'
      // },
      
    ]
  },
  
  {
    subheader: 'Ticket',
    items: [
      { title: 'Todos', path: PATH_DASHBOARD.todos, icon: ICONS.ecommerce, role:'user', },
      { title: 'Sin Asignar', path: PATH_DASHBOARD.bandejas, icon: ICONS.ecommerce, role:'user', llave:"woTickets"},
      { title: 'Asignadas', path: PATH_DASHBOARD.asignadas, icon: ICONS.ecommerce, role:'user' , llave:"asTickets"},
      { title: 'En Proceso', path: PATH_DASHBOARD.enProcesos, icon: ICONS.ecommerce, role:'user', llave:"epTickets" },
      { title: 'Resuelta', path: PATH_DASHBOARD.resuelta, icon: ICONS.ecommerce, role:'user', llave:"reTickets" },
      { title: 'Cerrado', path: PATH_DASHBOARD.cerrada, icon: ICONS.ecommerce, role:'user', llave:"ceTickets" },

    ]
  },
  // {
  //   subheader: 'Documental',
  //   items: [
  //     // MANAGEMENT : USER
  //     {
  //       title: 'Crear documento Dec',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.document,
  //       children: [
  //         // { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         // { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //         // { title: 'Memos', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'Crear Memo', path: PATH_DASHBOARD.eCommerce.newProduct },
  //         // { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //         { title: 'Crear Decreto', path: PATH_DASHBOARD.eCommerce.checkout },
  //         // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
  //       ],
  //       role: 'user'
  //     },
  //   ]
  // },
  {
    subheader: '',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Categorias',
        path: PATH_DASHBOARD.mantenedores.root,
        icon: ICONS.tuerca,
        children: [
          // { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          // { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
          // { title: 'Memos', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'Area', path: PATH_DASHBOARD.mantenedores.area },
          { title: 'Categoria', path: PATH_DASHBOARD.mantenedores.categorias },
          { title: 'SubCategoria', path: PATH_DASHBOARD.mantenedores.subcategorias },
          // { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
          // { title: 'Crear Decreto', path: PATH_DASHBOARD.eCommerce.checkout },
          // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
        ],
        role: 'AdminTicket'
      },
    ]
  },
  
//       {
//           title: 'Crear documento',
//           path: PATH_DASHBOARD.eCommerce.newProduct,
//           icon: ICONS.document,
//           // children: [
//           //   // { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
//           //   // { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
//           //   // { title: 'Memos', path: PATH_DASHBOARD.eCommerce.list },
//           //   { title: 'Crear Memo', path: PATH_DASHBOARD.eCommerce.newProduct },
//           //   // { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
//             // ---------- { title: 'Crear Decreto', path: PATH_DASHBOARD.eCommerce.checkout },
//           //   // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
//           // ],
//           role: 'user'
//         },
      
//       {
//         title: 'Bandeja de Entrada',
//         path: PATH_DASHBOARD.mail.root,
//         icon: ICONS.mail,
//         llave: "bandejaEntrada",
//         // info: (
//         //   <Label variant="outlined" color="error">
//         //     +1
//         //   </Label>
//         // ),
//         role: 'user'
//       },
      
//       // {
//       //   title: 'Permisos',
//       //   path: PATH_DASHBOARD.administrativos.root,
//       //   icon: ICONS.administrativos,
//       //   children: [
//       //     // { title: 'Solicitar Gas', path: PATH_DASHBOARD.gas.new },
//       //     { title: 'Administrativos', path: PATH_DASHBOARD.administrativos.list },
//       //   ],
//       //   role: 'user'
//       // },
//       {
//         title: 'Biblioteca Documental',
//         // path: PATH_DASHBOARD.documental.root,
//         path: PATH_DASHBOARD.documental.memo,
//         icon: ICONS.library,
//         // children: [
//         //   { title: 'Archivo', path: PATH_DASHBOARD.documental.shop },
//         // //  { title: 'Repositorio', path: PATH_DASHBOARD.documental.repositorio },
//         // ],
//         role: 'user'
//       },




//       // MANAGEMENT : BLOG
//       // {
//       //   title: 'blog',
//       //   path: PATH_DASHBOARD.blog.root,
//       //   icon: ICONS.blog,
//       //   children: [
//       //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
//       //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
//       //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
//       //   ],
//       // },
//     ],
//   },
// // hasta aquiiiiiiiiii-----------------------------------------

//   // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Internos',
  //   items: [
  //     {
  //       title: 'Ticket',
  //       path: PATH_DASHBOARD.ticket.root,
  //       icon: ICONS.printer,
  //       children: [
  //         { title: 'Solicitar Asistencia', path: PATH_DASHBOARD.ticket.new },
  //         { title: 'Mis Solicitudes', path: PATH_DASHBOARD.asistencia.list },
  //       ],
  //       // role: 'user'
  //       role: 'tonerUser'
  //     }
  //     ,
      
  //     {
  //       title: 'Toner',
  //       path: PATH_DASHBOARD.asistencia.root,
  //       icon: ICONS.printer,
  //       children: [
  //         { title: 'Solicitar Toner', path: PATH_DASHBOARD.asistencia.new },
  //         { title: 'Mis Solicitudes', path: PATH_DASHBOARD.asistencia.list },
  //       ],
  //       // role: 'user'
  //       role: 'tonerUser'
  //     },
  //     {
  //       title: 'TonerAdmin',
  //       path: PATH_DASHBOARD.tonerAdmin.admin,
  //       icon: ICONS.printer,
  //       // role: 'user'
  //       role: 'tonerAdmin'
  //     },
  //     {
  //       title: 'Emergencias',
  //       path: PATH_DASHBOARD.emergencias.root,
  //       icon: ICONS.emergencia,
  //       children: [
  //         { title: 'Ingresar Informe', path: PATH_DASHBOARD.emergencias.new },
  //         { title: 'Informes', path: PATH_DASHBOARD.emergencias.list },
  //       ],
  //       role: 'emergenciaUser'
  //       // role:'user'
  //     },
  //     {
  //       title: 'Informes Incidencia',
  //       path: PATH_DASHBOARD.emergencias.list,
  //       icon: ICONS.emergencia,
  //       role: 'emergenciaUserInc'
  //       // role:'user'
  //     },
  //     {
  //       title: 'Gas',
  //       path: PATH_DASHBOARD.gas.root,
  //       icon: ICONS.gas,
  //       children: [
  //         { title: 'Solicitar Gas', path: PATH_DASHBOARD.gas.new },
  //         { title: 'Mis Solicitudes', path: PATH_DASHBOARD.gas.list, 
  //       //     info: (
  //       //   <Label variant="outlined" color="error">
  //       //     +1
  //       //   </Label>
  //       // ),
  //         },
  //       ],
  //       llave:"gasUser",
  //       role: 'gasUser'
  //     },
  //     {
  //       title: 'GasAdmin',
  //       path: PATH_DASHBOARD.gasAdmin.admin,
  //       icon: ICONS.gas,
  //       role: 'gasAdmin',
  //       llave:"gasAdmin",

  //     },
  //     {
  //       title: 'Contrataciones',
  //       path: PATH_DASHBOARD.contratacion.root,
  //       icon: ICONS.handShake,
  //       children: [
  //         { title: 'Solicitar Contratación', path: PATH_DASHBOARD.contratacion.new },
  //         { title: 'Mis Solicitudes', path: PATH_DASHBOARD.contratacion.list },
  //       ],
  //       role: 'contractos',
  //       llave:"contratos",
  //       // role:'user'
  //     },
  //     {
  //       title: 'Contrataciones Para firmar',
  //       path: PATH_DASHBOARD.contratacionAdmin.admin,
  //       icon: ICONS.contract,
  //       role: 'firmarContratos',
  //       llave:"contratosFirma",

  //       // role: 'user'
  //     },
  //     {
  //       title: 'Presupuesto',
  //       path: PATH_DASHBOARD.presupuesto.root,
  //       icon: ICONS.presupuesto,
  //       children: [
  //         { title: 'Formulario Gastos', path: PATH_DASHBOARD.presupuesto.new },
  //         { title: 'Formulario Ingreso', path: PATH_DASHBOARD.presupuesto.ingreso},
  //         { title: 'Mis Formularios', path: PATH_DASHBOARD.presupuesto.list },
  //       ],
  //       role: 'presupuesto'
  //       // role: 'user'

  //     },
  //     {
  //       title: 'Presupuesto Admin',
  //       path: PATH_DASHBOARD.presupuestoAdmin.root,
  //       icon: ICONS.presupuesto,
  //       children: [
  //         { title: 'Formularios', path: PATH_DASHBOARD.presupuestoAdmin.admin },
  //         // { title: 'Formulario Ingreso', path: PATH_DASHBOARD.presupuesto.ingreso},
  //         { title: 'Items', path: PATH_DASHBOARD.presupuestoAdmin.list },
  //         { title: 'Programas', path: PATH_DASHBOARD.presupuestoAdmin.programas },

  //       ],
  //       role: 'presupuestoAdmin'
  //       // role: 'user'
  //     },
  //     { title: 'Bodega', path: PATH_DASHBOARD.bodega.root, icon: ICONS.bodega,  role: 'vehiculo' },
  //     { title: 'Solicitud Vehículo', path: PATH_DASHBOARD.vehiculos, icon: ICONS.calendar,  role: 'vehiculo' },
  //     { title: 'Calendario Vehículo', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar,  role: 'VehiculoAdmin' },
  //   ],
  // },

   // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Especificos',
  //   items: [
  //     // MANAGEMENT : USER
  //     {
  //       title: 'usuario',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         // { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         // { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         // { title: 'list', path: PATH_DASHBOARD.user.list },
          
  //         // { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //         { title: 'Cuenta', path: PATH_DASHBOARD.user.account },
  //         // { title: 'Solicitar Vacaciones', path: PATH_DASHBOARD.user.newUser },
  //       ],
  //       role: 'user'
  //     },
  //     {
  //       title: 'Subrogancias',
  //       path: PATH_DASHBOARD.subrrogancias.root,
  //       icon: ICONS.organigrama,
  //       children: [
  //         { title: 'Municipal', path: PATH_DASHBOARD.subrrogancias.municipal},
  //         { title: 'Salud', path: PATH_DASHBOARD.subrrogancias.salud },
  //         { title: 'Educación', path: PATH_DASHBOARD.subrrogancias.educacion},
  //       ],
  //       role: 'user'
  //     },
      

  //   ],
  // },
  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Bandejas',
  //   items: [
  //     // { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar,  role: 'user' },
  //     // { title: 'Funcionarios', path: PATH_DASHBOARD.funcionario, icon: ICONS.booking, role: 'user' },
  //     // {
  //     //   title: 'Memo',
  //     //   path: PATH_DASHBOARD.mail.root,
  //     //   icon: ICONS.mail,
  //     //   // info: (
  //     //   //   <Label variant="outlined" color="error">
  //     //   //     +1
  //     //   //   </Label>
  //     //   // ),
  //     //   role: 'user'
  //     // },
  //     {
  //       title: 'Decreto',
  //       path: PATH_DASHBOARD.decreto.root,
  //       icon: ICONS.mail,
  //       // info: (
  //       //   <Label variant="outlined" color="error">
  //       //     +1
  //       //   </Label>
  //       // ),
  //       role: 'user'
  //     },      
  //     // { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
     
      
  //   ],
  // },

  // { 
  //   subheader: 'Mantenedores',
  //   items: [
  //     {
  //       title: 'Usuario',
  //       path: PATH_DASHBOARD.mantenedorusuarios.root,
  //       icon: ICONS.tuerca,
  //       role: 'adm usuario municipal', 
  //     },
  //     {
  //       title: 'Subrogancia',
  //       path: PATH_DASHBOARD.mantenedorsubrogancias.root,
  //       icon: ICONS.tuercas,
  //       children: [
  //         { title: 'Municipal', path: PATH_DASHBOARD.mantenedorsubrogancias.municipal},
  //         { title: 'Salud', path: PATH_DASHBOARD.mantenedorsubrogancias.salud },
  //         { title: 'Educación', path: PATH_DASHBOARD.mantenedorsubrogancias.educacion },
          
  //       ],
  //       role: 'adm usuario municipal', 
  //     },
  //     {
  //       title: 'Departamento',
  //       path: PATH_DASHBOARD.mantenedordepartamentos.root,
  //       icon: ICONS.tuercas,
  //       children: [
  //         { title: 'Municipal', path: PATH_DASHBOARD.mantenedordepartamentos.municipal},
  //         { title: 'Salud', path: PATH_DASHBOARD.mantenedordepartamentos.salud },
  //         { title: 'Educación', path: PATH_DASHBOARD.mantenedordepartamentos.educacion },
          
  //       ],
  //       role: 'adm usuario municipal', 
  //     },
  //     {
  //       title: 'Vehículos',
  //       path: PATH_DASHBOARD.mantenedorvehiculo.root,
  //       icon: ICONS.car,
  //       role: 'adm usuario municipal', 
  //     },
      
  //     {
  //       title: 'Impresoras',
  //       path: PATH_DASHBOARD.mantenedorimpresora.root,
  //       icon: ICONS.printerAdmin,
  //       // role: 'user', 
  //       role: 'printerAdmin', 
  //     },

  //     // { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     // { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     // {
  //     //   title: 'kanban',
  //     //   path: PATH_DASHBOARD.kanban,
  //     //   icon: ICONS.kanban,
  //     // },
  //   ]
  // },
];

export default navConfig;
